import React from 'react'

const About = () => {
  return (
    <div name="about" className='w-full h-screen bg-gradient-to-b from-gray-800 to-black text-white pt-20' >
        <div className='max-w-screen-lg p-2 mx-auto flex flex-col justify-center w-full h-full' > 
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-gray-500'>About</p>
            </div>
            <p className='text-xl mt-20'>
            Hello! I'm Mohamed, a passionate front-end developer and student at EMSI Casablanca. My journey into web development began with curiosity about website creation, which quickly grew into a love for coding and design. I've invested time in learning online and mastering programming languages, enjoying the challenge of creating intuitive interfaces. I create web designs that are visually appealing and user-friendly. I also use Figma. I'm eager to keep growing as a developer and contribute to impactful projects.</p>         
        </div>
    </div>
  )
}

export default About