import React from 'react'
import HeroImage from "../assets/heroimage.png"
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { Link } from 'react-scroll'
import Typed from 'react-typed';
import { ReactTyped } from 'react-typed';

const Home = () => {
  return (
    <div name="home" className='h-screen w-full bg-gradient-to-b from-black via-black to-gray-800'>
      <div className='max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row'>
        <div className='flex flex-col justify-center h-full text-center md:text-left md:w-1/2 pt-20 md:pt-0'>
          <h2 className="text-4xl sm:text-7xl font-bold text-white">
            <span className="bg-gradient-to-r from-purple-500 to-blue-500 bg-clip-text text-transparent">
              I'm a </span> <br />
            <ReactTyped
              className="text-white"
              strings={[" Front-End Developer", " Programmer"]}
              typeSpeed={50}
              backSpeed={50}
              loop
            />
          </h2>
          <p className='text-gray-500 py-4 max-w-md mx-auto md:mx-0'>
            I love to work on web applications using technologies like React, Tailwind, NextJs,Figma.
          </p>

          <div> 
            <Link to="Projects" smooth duration={500} className='group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-purple-500 to-blue-500 cursor-pointer'>
              Projects
              <span className='group-hover:rotate-90 duration-300'>
                <MdOutlineKeyboardArrowRight size={30} className='ml-1' />       
              </span>
            </Link>
          </div>
        </div>
         <div className='mt-8 md:mt-0 md:w-1/2 '>
          <img 
            src={HeroImage} 
            alt="my profile" 
            className='rounded-2xl border-4 border-purple-500 mx-auto w-2/3 md:w-3/4' 
          />
        </div>
      </div>
    </div>
  )
}

export default Home
