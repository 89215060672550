import React from 'react';
import { FaGithub, FaLinkedin, FaEnvelope } from 'react-icons/fa';

const Contact = () => {
  return (
    <div name='contact' className='w-full h-screen bg-gradient-to-b from-black to-gray-800 p-4 text-white'>
      <div className='flex flex-col p-4 justify-center max-w-screen-lg mx-auto h-full'>
        <div className='pb-8'>
          <p className='text-4xl font-bold inline border-b-4 border-gray-500'>Contact</p>
          <p className='py-6'>Submit the form below to get in touch with me</p>
        </div>

        <div className='flex justify-center items-center'>
          <form action="https://getform.io/f/bejyozpa" method='POST' className='flex flex-col w-full md:w-1/2'>
            <input
              type="text"
              name="name"
              placeholder='Enter your name'
              className='my-4 p-2 bg-transparent border-2 rounded-md text-white focus:outline-none'
            />
            <input
              type="text"
              name="email"
              placeholder='Enter your email'
              className='p-2 bg-transparent border-2 rounded-md text-white focus:outline-none'
            />
            <textarea
              name="message"
              placeholder='Enter your message'
              rows="10"
              className='my-2 p-2 bg-transparent border-2 rounded-md text-white focus:outline-none'
            ></textarea>
            <button
              className='text-white bg-gradient-to-r from-purple-500 to-blue-500 px-6 py-3 my-8 mx-auto flex items-center rounded-md hover:scale-110 duration-300'
            >
              Let's talk
            </button>
          </form>
        </div>

        <div className='flex justify-center space-x-8 mt-8'>
          <a href="mailto:mohamed.maaninou@gmail.com" className='text-white hover:scale-110 duration-300'>
            <FaEnvelope size={30} />
          </a>
          <a href="https://www.linkedin.com/in/mohamed-maaninou-9bb709317/" target="_blank" rel="noopener noreferrer" className='text-white hover:scale-110 duration-300'>
            <FaLinkedin size={30} />
          </a>
          <a href="https://github.com/Rovio-junior" target="_blank" rel="noopener noreferrer" className='text-white hover:scale-110 duration-300'>
            <FaGithub size={30} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
